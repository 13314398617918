import { Routes } from "./routes/routes";
export { };

export const MainMenu: IMenuItems[] = [
    {
        label: "Home",
        path: Routes.home
    },
    {
        label: "About Us",
        path: Routes.about
    },
    {
        label: "Services",
        subMenu: [
            {
                label: "Custom Software Development",
                path: Routes.customSoftwareDevelopment
            },
            {
                label: "Web App Development",
                path: Routes.webAppDevelopment
            },
            {
                label: "Cloud and DevOps",
                path: Routes.cloudAndDevOps
            },
            {
                label: "Quality Assurance",
                path: Routes.qualityAssurance
            },
            {
                label: "Mobile App Development",
                path: Routes.mobileAppDevelopment
            },
            {
                label: "Product Engineering",
                path: Routes.productEngineering
            },
            {
                label: "Database Administration",
                path: Routes.databaseAdministration
            },
            {
                label: "Compliance and Risk Tracker",
                path: Routes.crt
            },
            {
                label: "Cyber Security",
                path: Routes.cyberSecurity
            },
        ]
    },
    {
        label: "Our Projects",
        subMenu: [
            {
                label: "Web Application",
                path: Routes.webApplication
            },
            // {
            //     label: "Asp.Net Websites",
            //     path: Routes.aspNetWebsites
            // },
            {
                label: "React & .Net Core",
                path: Routes.aspNetWebsites
            },
            {
                label: "Wordpress Websites",
                path: Routes.wordpressWebsites
            },
            {
                label: "PHP Websites",
                path: Routes.phpWebsites
            }
        ]
    },
    {
        label: "Company",
        subMenu: [
            {
                label: "Technologies",
                path: Routes.technologies
            },
            {
                label: "Career",
                path: Routes.career
            },
            {
                label: "Our Process",
                path: Routes.ourProcess
            },
            {
                label: "Outsourcing To Pakistan",
                path: Routes.outsourcingToPakistan
            },
        ]
    },
    {
        label: "Our Designs",
        subMenu: [
            {
                label: "Web Designs",
                path: Routes.webDesigns
            },
            {
                label: "Mobile Apps",
                path: Routes.mobileApps
            },
            {
                label: "Graphics Designs",
                path: Routes.graphicsDesigns
            },
            {
                label: "Logo Designs",
                path: Routes.logoDesigns
            },
            {
                label: "Flyer",
                path: Routes.flyer
            },
            {
                label: "Brochures",
                path: Routes.brochures
            },
            {
                label: "Corporate Identity",
                path: Routes.corporateIdentity
            },
        ]
    },
    {
        label: "Contact",
        path: Routes.contac
    }
]

export const adminDdMenu: TDropDownMenu = {
    services: false,
    ourProjects: false,
    company: false,
    ourDesigns: false
}

export const footerMenu: IMenuItems[] = [
    {
        label: "Custom Software Development",
        path: Routes.customSoftwareDevelopment
    },
    {
        label: "Web App Development",
        path: Routes.webAppDevelopment
    },
    {
        label: "Mobile App Development",
        path: Routes.mobileAppDevelopment
    },
    {
        label: "Cloud and DevOps",
        path: Routes.cloudAndDevOps
    },
    {
        label: "Product Engineering",
        path: Routes.productEngineering
    }
]

export const solutionData: ISolutionData[] = [
    {
        icon: './images/home/solution-icon-1.webp',
        title: 'Extended Team',
        text: 'Suggested For Long haul Development on-going help and mess with fixes Outsource explicit module'
    },
    {
        icon: './images/home/solution-icon-2.webp',
        title: 'Fixed Cost, Fixed Time',
        text: 'Suggested For Transient ventures Web or application advancement MVP advancement'
    },
    {
        icon: './images/home/solution-icon-3.webp',
        title: 'Dedicated Resources',
        text: 'Recommended For Works only on your project Follows your process Reports directly to you'
    },
    {
        icon: './images/home/solution-icon-4.webp',
        title: 'Additional Services',
        text: 'Recommended For Integration or Migration Work Product Engineering Research and Development'
    }
]

export const serviceData: IServiceData[] = [
    {
        icon: './images/home/service-1.webp',
        title: 'Custom Software Development',
        description: 'Get a custom solution developed and tailored to your requirements to elevate your business profits.',
        link: Routes.customSoftwareDevelopment
    },
    {
        icon: './images/home/service-2.webp',
        title: 'Web App Development',
        description: 'Expert soft Solution LLC has an extensive range of expertise in the field of Website Design.',
        link: Routes.webAppDevelopment
    },
    {
        icon: './images/home/service-3.webp',
        title: 'Cloud and DevOps',
        description: 'Computerized change is reshaping organizations all around the globe and DevOps and Cloud administrations.',
        link: Routes.cloudAndDevOps
    },
    {
        icon: './images/home/service-4.webp',
        title: 'Quality Assurance',
        description: 'Gain competitive edge with flawless technology solutions that are not only error-free, but also optimized to provide best-possible performance.',
        link: Routes.qualityAssurance
    },
    {
        icon: './images/home/service-5.webp',
        title: 'Mobile App Development',
        description: 'Expert soft Solution LLC provides. The Application Development, which delivers solutions tailored to the specific.',
        link: Routes.mobileAppDevelopment
    },
    {
        icon: './images/home/service-6.webp',
        title: 'Product Engineering',
        description: 'Expert soft Solution LLC provides. The  product engineering services which cover entire spectrum from design and development to deployment.',
        link: Routes.productEngineering
    },
    {
        icon: './images/home/service-7.webp',
        title: 'Database Administration',
        description: 'Expert soft Solution LLC provides. Database design, migration, performance monitoring, security, troubleshooting, backup and data recovery.',
        link: Routes.databaseAdministration
    },
    {
        icon: './images/home/service-8.webp',
        title: 'Cyber Security ',
        description: 'Expert soft Solution LLC provides. Measures to secure your website from cyberattacks. Web services security requirements are authenticated.',
        link: Routes.cyberSecurity
    },
]

export const projectData: IProjectData[] = [
    {
        icon: './images/home/crm.webp',
        title: 'CRM',
    },
    {
        icon: './images/home/goal.webp',
        title: 'On-Demand Solutions'
    },
    {
        icon: './images/home/Healthcare-Fitness.webp',
        title: 'Healthcare & Fitness'
    },
    {
        icon: './images/home/Ecommerce-Retail-B2B.webp',
        title: 'Ecommerce, Retail & B2B'
    },
    {
        icon: './images/home/Risk-Assessment.webp',
        title: 'Risk & Assessment'
    },
    {
        icon: './images/home/Education-eLearning.webp',
        title: 'Education & eLearning'
    },
    {
        icon: './images/home/data-warehouse.webp',
        title: 'File Management'
    },
    {
        icon: './images/home/network.webp',
        title: 'Social Networking'
    },
    {
        icon: './images/home/SE.webp',
        title: 'Sports & Entertainment'
    }
]

export const WebApplications: IProjectDetail[] = [
    {
        image: './images/projects/asp-websites/staffing-net.webp',
        title: 'Staffing.net.au',
        subTitle: 'Tools & Technologies Job Listing Portal',
        description: 'Asp.net Core 3.1, C#, MS SQL, JS & Jquery, Bootstrap, AmCharts.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.staffing.net.au/'
    },
    {
        image: './images/projects/asp-websites/rose-brand.webp',
        title: 'Rose Brand',
        subTitle: 'Tools & Technologies ECommerce Application',
        description: 'Asp.net Webforms, C#, MS SQL, JS & Jquery, Bootstrap, Payment Gateway',
        linkText: 'Visit Site',
        linkUrl: 'https://www.rosebrand.com/default.aspx'
    },
    {
        image: './images/projects/asp-websites/nassau.webp',
        title: 'Nassau',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap, SignalR, FirstData Payment Gateway, E-commerce, AmCharts.',
        linkText: 'Visit Site',
        linkUrl: 'https://nassautennis.net/'
    },
    {
        image: './images/projects/asp-websites/swimmer-stuff.webp',
        title: 'Swimmer Stuff',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net core, C#, MS SQL, JS & jQuery, Bootstrap , PayPal, E-commerce.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.swimmerstuff.com/'
    },
    {
        image: './images/projects/asp-websites/print-head-studio.webp',
        title: 'Print Head Studio',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.printheadstudio.com/'
    },
    {
        image: './images/projects/asp-websites/bloss-fest.webp',
        title: 'Bloss Fest',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.blossfest.com/'
    },
    {
        image: './images/projects/asp-websites/brainstormer-trivia.webp',
        title: 'Brainstormer Trivia',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap, SignalR.',
        linkText: 'Visit Site',
        linkUrl: 'https://brainstormer.com/'
    },
    {
        image: './images/projects/asp-websites/swag-decor.webp',
        title: 'Swag Decor',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net Webforms, C#, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://swagdecor.com/'
    }
]
export const DotNetProjects: IProjectDetail[] = [
    {
        image: './images/projects/asp-websites/staffing-net.webp',
        title: 'Staffing.net.au',
        subTitle: 'Tools & Technologies Job Listing Portal',
        description: 'Asp.net Core 3.1, C#, MS SQL, JS & Jquery, Bootstrap, AmCharts.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.staffing.net.au/'
    },
    {
        image: './images/projects/asp-websites/rose-brand.webp',
        title: 'Rose Brand',
        subTitle: 'Tools & Technologies ECommerce Application',
        description: 'Asp.net Webforms, C#, MS SQL, JS & Jquery, Bootstrap, Payment Gateway',
        linkText: 'Visit Site',
        linkUrl: 'https://www.rosebrand.com/default.aspx'
    },
    {
        image: './images/projects/asp-websites/nassau.webp',
        title: 'Nassau',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap, SignalR, FirstData Payment Gateway, E-commerce, AmCharts.',
        linkText: 'Visit Site',
        linkUrl: 'https://nassautennis.net/'
    },
    {
        image: './images/projects/asp-websites/swimmer-stuff.webp',
        title: 'Swimmer Stuff',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net core, C#, MS SQL, JS & jQuery, Bootstrap , PayPal, E-commerce.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.swimmerstuff.com/'
    },
    {
        image: './images/projects/asp-websites/print-head-studio.webp',
        title: 'Print Head Studio',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.printheadstudio.com/'
    },
    {
        image: './images/projects/asp-websites/bloss-fest.webp',
        title: 'Bloss Fest',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.blossfest.com/'
    },
    {
        image: './images/projects/asp-websites/brainstormer-trivia.webp',
        title: 'Brainstormer Trivia',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net MVC, C#, MS SQL, JS & Jquery, Bootstrap, SignalR.',
        linkText: 'Visit Site',
        linkUrl: 'https://brainstormer.com/'
    },
    {
        image: './images/projects/asp-websites/swag-decor.webp',
        title: 'Swag Decor',
        subTitle: 'Tools & Technologies',
        description: 'Asp.net Webforms, C#, JS & Jquery, Bootstrap.',
        linkText: 'Visit Site',
        linkUrl: 'https://swagdecor.com/'
    }
]

export const ReactAndDotNetProjects: IProjectDetail[] = [
    {
        image: './images/projects/react-dot-net-core/bastasfift.webp',
        title: 'Basta Shift',
        subTitle: 'Tools & Technologies',
        description: 'C#,.Net Core, React, TypeScript, MUI, React Bootstrap, Tailwind',
        linkText: 'Visit Site',
        linkUrl: 'https://app.basta-shift.de/',
        styleClass: 'obj-position-left'
    },
    {
        image: './images/projects/react-dot-net-core/goroger.webp',
        title: 'HKP-Hero',
        subTitle: 'Tools & Technologies',
        description: 'C#,.Net Core, React, TypeScript, MUI, React Bootstrap, Tailwind',
        linkText: 'Visit Site',
        linkUrl: 'https://app.hkp-hero.de/'
    },
    {
        image: './images/projects/react-dot-net-core/bonjan-jobs.webp',
        title: 'BonJan Job',
        subTitle: 'Tools & Technologies',
        description: 'C#,.Net Core, React, TypeScript, MUI, React Bootstrap, Tailwind',
        linkText: 'Visit Site',
        linkUrl: 'https://bonjanjob.com/'
    },
    {
        image: './images/projects/react-dot-net-core/rd-yes.webp',
        title: 'RDyes',
        subTitle: 'Tools & Technologies',
        description: 'C#,.Net Core, React, TypeScript, MUI, React Bootstrap, Tailwind',
        linkText: 'Visit Site',
        linkUrl: 'https://rdyes.com/'
    },
    {
        image: './images/projects/react-dot-net-core/lead-form.webp',
        title: 'Lead Form',
        subTitle: 'Tools & Technologies',
        description: 'C#,.Net Core, React, TypeScript, MUI, React Bootstrap, Tailwind',
        linkText: 'Visit Site',
        linkUrl: 'https://leadpagemagnet.com/login?redirect_url=/editForm/0969c28d-99ff-4d41-968b-6e4b0e678647/37758783-e3fb-4b8d-912f-c9bdc5ae75f3/'
    }

]

export const WordpressProjects: IProjectDetail[] = [
    {
        image: './images/projects/wordpress-websites/new-cyturus.webp',
        title: 'Cyturus',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://cyturus.com/'
    },
    {
        image: './images/projects/wordpress-websites/kt-fitness.webp',
        title: 'KT Fitness',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://kathrynthomaspt.co.uk/'
    },
    {
        image: './images/projects/wordpress-websites/focus-shift.webp',
        title: 'Focus Shift',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.focusshiftfilms.com/'
    },
    {
        image: './images/projects/wordpress-websites/floatation-therapy.webp',
        title: 'Floatation Therapy',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.floatationtherapyireland.com/'
    },
    {
        image: './images/projects/wordpress-websites/new-vantagepoint.webp',
        title: 'Vantage Point',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://vantagepoint.wales/'
    },
    {
        image: './images/projects/wordpress-websites/new-stage-works.webp',
        title: 'Stage Works YouTheatre',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://stageworksyouththeatre.com/'
    },
    {
        image: './images/projects/wordpress-websites/rugby-smarts-project.webp',
        title: 'Rugby Smarts',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://rugbysmarts.com/'
    },

    {
        image: './images/projects/wordpress-websites/unisearch-project.webp',
        title: 'Unisearch',
        subTitle: 'Used Plugins',
        description: 'Slider Revolution, Font Awesome, ThemePunch',
        linkText: 'Visit Site',
        linkUrl: 'https://www.cogencyglobal.com/unisearch?utm_campaign=Unisearch+Redirect'
    },
    {
        image: './images/projects/wordpress-websites/safe-meridian-project.webp',
        title: 'Safe Meridian',
        subTitle: 'Used Plugins',
        description: 'Orbit Fox by ThemeIsle, Google Analytics, Wp-Forms.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.safemeridian.com/'
    },
    // {
    //     image: './images/projects/wordpress-websites/briarwood-surgical-project.webp',
    //     title: 'Briarwood Surgical',
    //     subTitle: 'Used Plugins',
    //     description: 'WP-Bakery Page Builder, Revolution Slider, Wp-Forms, Ultimate Addons.',
    //     linkText: 'Visit Site',
    //     linkUrl: 'https://www.briarwoodsurgical.com/'
    // },
    {
        image: './images/projects/wordpress-websites/iserveu.webp',
        title: 'Iserveu',
        subTitle: 'Used Plugins',
        description: 'Wp-bakery Page Builder, Contact Form, Revolution Slider.',
        linkText: 'Visit Site',
        linkUrl: 'https://www.iserveu.com.au/'
    },
    {
        image: './images/projects/wordpress-websites/medicalcaremedicinesS-1.webp',
        title: 'Medicalcare Medicines',
        subTitle: 'Used Plugins',
        description: 'Font Awesome, Google Analytics, Contact Form.',
        linkText: 'Visit Site',
        linkUrl: ''
    },
    // {
    //     image: './images/projects/wordpress-websites/riffinogroupss.webp',
    //     title: 'Riffino Group',
    //     subTitle: 'Used Plugins',
    //     description: 'WP-Forms, Revolution Slider, Google Analytics.',
    //     linkText: 'Visit Site',
    //     linkUrl: 'http://riffinogroup.com/'
    // },
    {
        image: './images/projects/wordpress-websites/clickoness.webp',
        title: 'ClickOne',
        subTitle: 'Used Plugins',
        description: 'Font Awesome, Google Analytics, Contact Form.',
        linkText: 'Visit Site',
        linkUrl: ''
    }
]

export const PHPProjects: IProjectDetail[] = [
    // {
    //     image: './images/projects/php-websites/briarwoodsurgical.webp',
    //     title: 'Briarwood Surgical',
    //     subTitle: '',
    //     description: '',
    //     linkText: 'Visit Site',
    //     linkUrl: 'https://www.briarwoodsurgical.com/'
    // },
    {
        image: './images/projects/php-websites/expert-connect.webp',
        title: 'Expert Connect',
        subTitle: 'Tools & Technologies',
        description: 'Tech stack: PHP Laravel, Java, Twilio ',
        linkText: 'Visit Site',
        linkUrl: 'https://www.xpertconnect.pro/'
    },
    {
        image: './images/projects/php-websites/gulfmarine.webp',
        title: 'Gulf Marine',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'http://gulfmarine.co.uk/'
    },
    {
        image: './images/projects/php-websites/sahi.webp',
        title: 'Sahi.ae',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'http://www.sahi.ae/'
    },
    {
        image: './images/projects/php-websites/fak.webp',
        title: 'Fynk Pharma',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'https://www.fynkpharma.com/'
    },
    {
        image: './images/projects/php-websites/dataxap.webp',
        title: 'DataXap',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'http://www.dataxap.com/index.php'
    },
    {
        image: './images/projects/php-websites/karmel.webp',
        title: 'Karmel Contracting',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'http://www.karmelcontracting.com/'
    },
    {
        image: './images/projects/php-websites/fak.webp',
        title: 'Fak Group',
        subTitle: '',
        description: '',
        linkText: 'Visit Site',
        linkUrl: 'http://fak-group.com/'
    },
]

export const TechnologiesData: ITechnology[] = [
    {
        path: './images/company/technologies/netLogo-pj8uojbn85gzmbjb35zypvj3r2ni0v40wahktrjkzk.webp',
        alt:'dot-net'
    },
    {
        path: './images/company/technologies/React-Js-pje1ssmzm4whf4qt2g97bi5q76ho3wlbdoctslmj9c.webp',
        alt:'react-js'
    },
    {
        path: './images/company/technologies/abp-logo-long-pjaeth9n7tznke0578ai4ebqrrokhf45312v7t2l1c.webp',
        alt:'asp-dot-net-boilerplate'
    },
    {
        path: './images/company/technologies/zero-big-logo-pjaet9qxp5pczib2f51hkg820opmruaadzuzdldqf4.webp',
        alt:'asp-dot-net-zero'
    },
    {
        path: './images/company/technologies/SQL-Server-pjafk7sdm6l9t3612sch1nfr2bwffh9a3d42n5fg1s.webp',
        alt:'sql-server'
    },
    {
        path: './images/company/technologies/postgresql.webp',
        alt:'postgresql',
        cssClass: 'postgresql-logo'
    },
    {
        path: './images/company/technologies/25-1-pj04u4g820snrd40vv5k7hoeht3cju70ic6mf9tam8.webp',
        alt:'node-js'
    },
    {
        path: './images/company/technologies/React-Native-pjae44kcvpa8f0tw8rrrdan5ymn5vbgrxjme84nuv4.webp',
        alt:'react-native'
    },
    {
        path: './images/company/technologies/1-pja3zcd6lbx8qwp5blb07uiaoem3o0aus19qfbj3ls.webp',
        alt:'dev-express'
    },
    {
        path: './images/company/technologies/Python-Logo-Final.webp',
        alt:'python'
    },
    {
        path: './images/company/technologies/django.webp',
        alt:'django'
    },
    {
        path: './images/company/technologies/flask.webp',
        alt:'flask'
    },
    {
        path: './images/company/technologies/boilerplate.webp',
        alt:'boilerplate'
    },
    {
        path: './images/company/technologies/aws.webp',
        alt:'aws'
    },
    {
        path: './images/company/technologies/PHP-logo-final-pj9zc05jw4uju1rngrb1tkp3hxeobuz5fs6toxn9c0.webp',
        alt:'php'
    },
    {
        path: './images/company/technologies/233-pj04ub13dv1o0mugtfzy6y0mni6x1px4v8r0s7jjeo.webp',
        alt:'my-sql'
    },
    {
        path: './images/company/technologies/Wp-Logo-pjdvnlab9q396q20qu1wjboliss0o6fcytf80dlvk0.webp',
        alt:'wordpress'
    },
    {
        path: './images/company/technologies/6-4-pj04u2kjocq3456r6ucb2i5hb1cm4fzju2vngpw2yo.webp',
        alt:'docker'
    },
    {
        path: './images/company/technologies/Bootstrap.webp',
        alt:'bootstrap'
    },
    {
        path: './images/company/technologies/JavaScript.webp',
        alt:'java-script'
    },
    {
        path: './images/company/technologies/jquery.webp',
        alt:'jquery'
    },
    {
        path: './images/company/technologies/Telerik.webp',
        alt:'telerik'
    },
    {
        path: './images/company/technologies/MUI.webp',
        alt:'material-ui'
    },
    {
        path: './images/company/technologies/Fabric.webp',
        alt:'fabric-js'
    },
    {
        path: './images/company/technologies/KonvaJS.webp',
        alt:'konva-js'
    },
    {
        path: './images/company/technologies/chakra.webp',
        alt:'chakra-ui'
    },
    {
        path: './images/company/technologies/Tailwind.webp',
        alt:'tailwind-css'
    },
    {
        path: './images/company/technologies/QL.webp',
        alt:'graph-ql'
    },
    {
        path: './images/company/technologies/MS-Azure.webp',
        alt:'microsoft-azure'
    },
    {
        path: './images/company/technologies/Amchart.webp',
        alt:'amcharts'
    },
    {
        path: './images/company/technologies/SignalR-Final.webp',
        alt:'signalR'
    },
    {
        path: './images/company/technologies/syncfusion.webp',
        alt:'syncfusion'
    },
    {
        path: './images/company/technologies/netCore.webp',
        alt:'dot-net-core',
        cssClass: 'dotNet-html-logo'
    },
    {
        path: './images/company/technologies/HTML-5.webp',
        alt:'html5',
        cssClass: 'tech-html-logo'
    },
    {
        path: './images/company/technologies/css3.webp',
        alt:'css3',
        cssClass: 'tech-css-logo'
    },
];

export const OTPlist: TListItem[] = [
    {
        listItem: 'Priority industry in Pakistan'
    },
    {
        listItem: 'Tax holiday for information technology industry in Pakistan'
    },
    {
        listItem: 'Availability of low cost but highly talented workforce'
    },
    {
        listItem: 'Low cost of software development and website development in Pakistan'
    },
    {
        listItem: 'Pakistanis are good at communication in English'
    },
    {
        listItem: 'Excellent infrastructure to do financial transactions for projects'
    },
    {
        listItem: 'Fast and reliable internet services are available in Pakistan'
    },
    {
        listItem: 'Pakistani government and companies are continuously investing in new hardware upgrades to make services better'
    }
]