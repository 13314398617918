import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export const DisplayStart = styled.div<{
  width?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  verticalAlign?: string;
  flexDirection?: string;
}>`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "center"};
  justify-content: start;
`;

export const DisplayCenter = styled.div<{
  width?: string;
  height?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  verticalAlign?: string;
  flexDirection?: string;
}>`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin-bottom: ${(props) => (props.height ? props.height : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "center"};
  justify-content: center;
`;

export const DisplayEnd = styled.div<{
  width?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  verticalAlign?: string;
  flexDirection?: string;
}>`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "center"};
  justify-content: end;
`;

export const DisplayBetween = styled.div<{
  width?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  verticalAlign?: string;
  flexDirection?: string;
}>`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "center"};
  justify-content: space-between;
`;

export const InlineBox = styled.div<{
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  cursor?: string;
  width?: string;
}>`
  display: inline-block;
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
  width: ${(props) => (props.width ? props.width : null)};
`;
export const Box = styled.div<{
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  cursor?: string;
  width?: string;
}>`
  display: block;
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
  width: ${(props) => (props.width ? props.width : null)};
`;

export const BodyText = styled.div<{
  fontSize?: string;
  fontWeight?: string;
  textColor?: string;
  textAlign?: string;
  lineHeight?: string;
  fontfamily?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  pl?: string;
  pr?: string;
  pt?: string;
  pb?: string;
  display?: string;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-family: ${(props) =>
    props.fontfamily ? props.fontfamily : "Livvic"} !important;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.5")};
  color: ${(props) => (props.textColor ? props.textColor : "#454545")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  display: ${(props) => (props.display ? props.display : null)};
`;

export const CustomButton = styled(Button)`
  font-size: ${(props: {
    fontSize?: string;
    fontWeight?: string;
    bgColor?: string;
    borderColor?: string;
    textColor?: string;
    textColorHover?: string;
    disabled?: boolean;
    mt?: string;
    mb?: string;
    ml?: string;
    mr?: string;
  }) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : "")};
  color: ${(props) => (props.textColor ? props.textColor : "#454545")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-left: ${(props) => (props.ml ? props.ml : "0px")};
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
  &.btn:hover {
    background-color: ${(props) =>
      props.bgHoverColor ? props.bgHoverColor : "#fff"} !important;
    border-color: ${(props) =>
      props.borderColor ? props.borderColor : ""}!important;
    color: ${(props) => (props.textColorHover ? props.textColorHover : "#fff")};
  }
  &.btn:disabled {
    background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
    border-color: ${(props) => (props.borderColor ? props.borderColor : "")};
    color: ${(props) => (props.textColor ? props.textColor : "#454545")};
    opacity: 0.25;
  }
`;

export const HomePageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const MainLayoutWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const HeaderWrapper = styled.div`
  padding: 10px 0px;
  position: relative;
  z-index: 2;
`;

export const SliderWrapper = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
  margin-top: -144px;
  @media (max-width: 1280px) {
    margin-top: -214px;
  }
`;

export const SlideBox = styled.div<{
  bgimg: string;
}>`
  background-image: url(${(props) => props.bgimg});
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100%;
`;

export const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
export const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 100px;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-left: 80px;
    padding-top: 80px;
  }
  @media (max-width: 768px) {
    padding-left: 60px;
    padding-top: 50px;
  }
  @media (max-width: 500px) {
    padding-left: 20px;
    padding-top: 50px;
  }
`;
export const SlideTopHeading = styled.div`
  font-family: "Livvic", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 0;
  letter-spacing: 1.5px;
  color: #fff;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) and (min-width: 470px) {
    font-size: 14px;
    margin-bottom: 15px;
  }
  @media (max-width: 469px) {
    font-size: 13px;
    margin-bottom: 20px;
  }
`;
export const SlideMainHeading = styled.div`
  font-family: "Livvic", Sans-serif;
  text-transform: uppercase;
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 26px;
  padding: 0;
  letter-spacing: 0;
  color: #fff;
  margin-bottom: 30px;
  text-align: left;
  @media (max-width: 991px) {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 15px;
  }
  @media (max-width: 500px) and (min-width: 470px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  @media (max-width: 469px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
`;
export const SlideBottmHeading = styled.div`
  font-family: "Livvic", Sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 42px;
  color: #fff;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    font-size: 29px;
    line-height: 39px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) and (min-width: 470px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  @media (max-width: 469px) {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
`;
export const SlideButton = styled.div<{
  varient?: string;
}>`
  color: ${(props) => (props.varient === "orange" ? "#e47226" : "#fff")};
  border: 2px solid
    ${(props) => (props.varient === "orange" ? "#e47226" : "#fff")};
  font-family: "Livvic", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 30px;
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  padding: 12px 36px;
  text-align: center;
  &:hover {
    color: #fff;
    border: 2px solid #e47226;
    background: #e47226;
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 500px) {
  }
`;

export const MobileMenuWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
`;

export const FooterWrapper = styled.div`
  padding: 92px 0 45px;
  background-image: url("./images/footerbg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f6f9;
  @media (max-width: 991px) {
    padding: 50px 0 45px;
  }
`;

export const FooterSocial = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  & > li {
    display: inline-block;
    margin-right: 6px;
    text-align: center;
    border-radius: 30px;
  }
  & > li a {
    font-size: 16px;
    margin-right: 3px;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.9);
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > li a:hover {
    color: #fff !important;
    background: #e47226 !important;
  }
`;
export const FooterTitle = styled.div`
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  margin-bottom: 25px;
  color: #111111;
`;

export const FooterSecondWrapper = styled.div`
  padding-left: 70px;
  @media (max-width: 991px) {
    padding-left: 0px;
    margin-top: 15px;
  }
`;
export const FooterLastWrapper = styled.div`
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

export const ServicesMenu = styled.ul`
  margin-top: -5px;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #454545;
  text-align: left;
  & li {
    box-sizing: inherit;
    position: relative;
    margin-bottom: 5px;
    text-align: left;
  }
  & li a {
    position: relative;
    color: #454545;
    font-size: 16px;
    padding: 6px 0;
    display: inline-block;
    transition: all 0.4s ease-in-out 0s;
    text-decoration: none;
    outline: none !important;
    text-align: left;
  }
  & li a:hover {
    color: #e47226;
  }
`;
export const ContactList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: 0;
  text-align: left;
  padding-right: 40px;
  padding-bottom: 10px;
  & li {
    box-sizing: inherit;
    position: relative;
    margin-bottom: 5px;
    text-align: left;
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 18px;
  }
  & li .contact-icon {
    margin-right: 20px;
    font-size: 20px;
    color: #e47226 !important;
    font-weight: 400 !important;
  }
  & li .contact-text {
    color: #454545;
    font-size: 16px;
    text-align: left;
  }
`;
export const FooterBottomWrapper = styled.div`
background: #1f64a4;
padding:20px 15px;
color:#fff !important;
}
`;

export const HomeContentWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const HeadingTopLabel = styled.div<{
  bgColor?: string;
  textColor?: string;
}>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#E4722621")};
  border-radius: 30px;
  margin: 0 0 10px;
  padding: 6px 25px;
  display: inline-block;
  color: ${(props) => (props.textColor ? props.textColor : "#e47226")};
  font-weight: 400;
  text-transform: uppercase;
  line-height: 28px;
  font-family: "Livvic", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
`;
export const HeadingTitle = styled.div<{
  textColor?: string;
  textAlign?: string;
  fontsize?: string;
}>`
  font-family: "Livvic", sans-serif;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "36px")};
  font-weight: 700;
  line-height: 1.3em;
  position: relative;
  color: ${(props) => (props.textColor ? props.textColor : "#0a0a0a")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin-bottom: 15px;
  @media (max-width: 630px) {
    font-size: 28px;
  }
`;
export const SolutionWrapper = styled.div`
  text-align: center;
  padding: 35px;
  position: relative;
  &:hover > div:before {
    border: none !important;
  }
`;
export const Patrenimg = styled.img`
  position: relative;
  width: unset;
  height: unset;
  position: absolute;
  top: 40px;
  right: 70px;
  transition: all 0.3s ease 0s;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
`;
export const SolutionIconBox = styled.div`
  background-color: #fff;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto 42px;
  position: relative;
  box-shadow: 0 28px 28px 0 rgba(108, 88, 250, 0.2);
  padding: 0;
  display: inline-block;
  font-size: 50px;
  color: #061340;
  transition: all ease 0.3s;
  &:before {
    position: absolute;
    content: "";
    right: -1px;
    bottom: -2px;
    width: 112px;
    height: 112px;
    border-radius: 100%;
    border: 1px solid;
    transition: all 0.3s ease 0s;
    z-index: 0;
    border-color: #1443c61c !important;
  }
  & img {
    padding: 0;
  }
`;
export const SolutionTextBox = styled.div`
  padding: 10px 0 0;
  position: relative;
`;
export const SolutionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #0a0a0a;
  line-height: 32px;
  font-family: Livvic;
  letter-spacing: 0;
`;
export const SolutionText = styled.div`
  margin: 0;
  transition: 0.4s;
  text-align: center;
  color: #454545 !important;
  font-family: Livvic !important;
  font-size: 18px !important;
`;

export const AboutUsSectionWrapper = styled.div`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 125px 0 15px;
  position: relative;
  background-color: #f6f6f9;
`;

export const ShapeTiltTop = styled.div`
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
  & .shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
  }
  & svg {
    width: calc(149% + 1.3px);
    height: 78px;
    z-index: -1;
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const AboutSectionShapeImg = styled.div`
position: relative;
}
& .pattern {
    position: absolute;
    left: -31px;
    top: -72px;
  }
& img{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}
& .middle-image {
    position: relative;
    z-index: 1;
  }
& .shape {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
export const TesxtRightWrapper = styled.div`
  padding: 55px 0 0 70px;
  @media (max-width: 991) {
    padding: 0px 0 0 0px;
  }
  @media (max-width: 660px) {
    padding: 48px 0 0 0px;
  }
`;

export const TitleDescription = styled.div<{
  fontsize?: string;
}>`
  margin: 0 0 10px;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "18px")};
  font-weight: 400;
  color: #454545;
`;

export const ButtonGradient = styled.div<{
  iscaptilize?: boolean;
}>`
  padding: 14px 40px;
  background-color: transparent;
  background-image: linear-gradient(250deg, #4e95ed 19%, #03228f 100%);
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  text-transform: ${(props) => (props.iscaptilize ? "Captilize" : "uppercase")};
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  font-weight: 500;
  line-height: 26px;
  z-index: 1;
  &:hover {
    background-color: transparent;
    background-image: linear-gradient(180deg, #03228f 19%, #4e95ed 100%);
  }
`;
export const ServicesShapeWrapper = styled.div`
  padding: 50px 0 10px;
  position: relative;
`;
export const ShapeTiltBottom = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
  & .shape-fill {
    fill: #f6f6f9;
    transform-origin: center;
    transform: rotateY(0deg);
  }
  & svg {
    width: calc(176% + 1.3px);
    height: 181px;
    display: block;
    position: relative;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
  }
`;
export const ServicesTitleWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 0;
  @media (max-width: 991px) {
    margin-top: 0px;
  }
`;
export const ServiceBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
  border-radius: 0;
  box-shadow: -13px -13px 58px -13px rgba(0, 0, 0, 0.03);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
`;

export const FlipBox = styled.div`
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    font-size: 0;
    box-sizing: border-box;
  }
  &:hover .front-part {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
  &:hover .back-part {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
`;
export const FlipBoxInner = styled.div`
position: relative;
-webkit-transform: translateZ(0);
-webkit-perspective: 1000px;
perspective: 1000px;
}
`;
export const FlipBoxFront = styled.div`
border-style: solid;
border-width: 0;
text-align: center;
padding: 70px 20px;
align-items: center;
border-radius: 10px;
-webkit-transform: rotateX(0);
transform: rotateX(0);
position: relative;
bottom: 0;
z-index: 10;
top: 0;
right: 0;
left: 0;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-webkit-align-items: center;
align-items: center;
-ms-flex-align: center;
height: 300px;
background-color: transparent;
background-position: center;
background-clip: padding-box;
background-size: cover;
background-repeat: no-repeat;
text-align: center;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
-webkit-perspective: 1000px;
perspective: 1000px;
-webkit-transition: -webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: -webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: transform .6s cubic-bezier(.2,.85,.4,1.275),-webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
&:before{
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9;
    width: 100%;
    height: 100%;
    content: "";
    text-align: center;
    box-sizing: border-box;
}
}
`;
export const FlipBoxBack = styled.div`
padding: 70px 20px;
align-items: center;
border-radius: 10px;
-webkit-transform: rotateX(180deg);
transform: rotateX(180deg);
position: absolute;
top: 0;
right: 0;
left: 0;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-webkit-align-items: center;
align-items: center;
-ms-flex-align: center;
height: 300px;
background-color: transparent;
background-position: center;
background-clip: padding-box;
background-size: cover;
background-repeat: no-repeat;
text-align: center;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
-webkit-perspective: 1000px;
perspective: 1000px;
-webkit-transition: -webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: -webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: transform .6s cubic-bezier(.2,.85,.4,1.275);
transition: transform .6s cubic-bezier(.2,.85,.4,1.275),-webkit-transform .6s cubic-bezier(.2,.85,.4,1.275);
&:before{
    border-radius: 10px;
    background-color: transparent;
    background-image: linear-gradient(90deg,#1672de 0%,#071f6b 58%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9;
    width: 100%;
    height: 100%;
    content: "";
    box-sizing: border-box;
}
}
`;
export const FlipBoxContent = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  transition: 0.95s;
`;
export const FlipBoxFrontIcon = styled.div`
  margin-bottom: 21px;
  & > span {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 30px;
    line-height: 1;
  }
  & > span > img {
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;
export const FlipBoxFronTitle = styled.div`
  margin-bottom: 10px;
  & > h3 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    color: #101010;
    font-family: Livvic;
    text-align: center;
  }
`;
export const FlipBoxFrontDescription = styled.div`
  & > p {
    color: #444;
    margin-bottom: 0;
    font-size: 16px;
  }
`;
export const FlipBoxBackTitle = styled.div`
  margin-bottom: 10px;
  & > h3 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    padding: 0;
    letter-spacing: 0;
  }
  & > h3 > .title-link {
    color: #fff;
    box-shadow: none;
    text-decoration: none;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out 0s;
  }
`;
export const FlipBoxBackDescription = styled.div`
  margin-bottom: 25px;
  & > p {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
    box-sizing: border-box;
  }
`;
export const FlipBoxBackButton = styled.div`
  & .back-btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #111;
    background-color: #fff;
    border-radius: 30px;
    display: inline-block;
    line-height: 1;
    background: #fff;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ServiceSectionShapeImg = styled.div`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    transform var(--e-transform-transition-duration, 0.4s);
  position: relative;
  & .pattern {
    position: absolute;
    left: -120px;
    top: -450px;
  }
`;

export const ProjectSectionWrapper = styled.div`
  background-color: #000060;
  background-image: url("./images/home/project-section-bg.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 50px;
  padding: 70px 0;
`;

export const ProjectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  transition: 0.4s;
  overflow: hidden;
  margin-top: 60px;
  &:hover .project-title {
    color: #e47226;
  }
`;
export const ProjectImg = styled.img`
  width: 100px;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
`;
export const ProjectTitle = styled.div`
  padding: 10px 0 0;
  position: relative;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  font-family: Livvic;
`;
export const ProcessSectionWrapper = styled.div`
  background-image: url("./images/home/process_dot.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
`;

export const CounterListItem = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 991px) {
    justify-content: center;
    &.mb-30px-md {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 500px) {
    &.mb-30px-sm {
      margin-bottom: 30px;
    }
  }
`;
export const CounterIcon = styled.div`
  display: inline-block;
  padding-right: 15px;
  width: 86px;
`;
export const MainLayoutHeaderWrapper = styled.div`
  position: relative;
`;
export const MainLayoutHeaderTopWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;
export const MainLayoutLogoWrapper = styled.div`
  display: flex;
  flex: 1;
  & .logo-area {
    position: relative;
    z-index: 9;
    float: left;
    margin-right: 20px;
    line-height: 40px;
  }
  & .logo-area img {
    max-height: 75px;
    display: inline-block;
    vertical-align: middle;
  }
  @media (max-width: 991px) {
    & .logo-area img {
      display: none;
    }
  }
`;
export const HeaderQuoteBox = styled.div`
  width: 90%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .borderLeft:before {
    content: "";
    height: 35px;
    width: 1px;
    background-color: #eee;
    display: block;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    width: 70%;
    margin-left: 0px;
    margin-right: auto;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: start;
    & .borderLeft:before {
      content: "";
      height: 7px;
      width: 1px;
      background-color: #fff;
      display: block;
    }
  }
`;
export const HeaderIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  & .icon-box {
    font-size: 26px;
    color: #e47226 !important;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  & .icon-box img {
    height: 28px;
  }
`;
export const HeaderIconTextBox = styled.div`
  & .info-title {
    font-size: 15px;
    font-weight: 500;
    color: #0a0a0a;
    line-height: 1;
    padding-top: 2px;
  }
  & .info-des {
    color: #0a0a0a;
    font-size: 14px;
    font-weight: 400;
  }
`;
export const MainLayoutMenuWrapper = styled.div`
  background-image: linear-gradient(90deg, #1f64a4 10%, #03228f 100%);
`;
export const MainLayoutMobileLogoWrapper = styled.div`
  & img {
    display: none;
  }
  @media (max-width: 991px) {
    & img {
      display: block !important;
    }
  }
`;
export const DotLine = styled.div`
  height: 5px;
  width: 90px;
  background: #1273eb;
  margin: 0;
  position: relative;
  border-radius: 30px;
  background-color: #e4722621;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #e47226;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-Rs-Dot;
    animation-name: MOVE-Rs-Dot;
  }
`;
export const CounterSectionWrapper = styled.div`
  background-color: #000060;
  background-image: url("./images/home/portfolio-bg2.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  ransition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 40px;
  margin-bottom: 0;
  padding: 60px 0 190px;
`;

export const CounterTitle = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  transition: all 0.3s ease 0s;
`;
export const CounterText = styled.span`
  color: #fff;
  font-size: 53px;
  font-weight: 700;
  -webkit-text-fill-color: #042ba1;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;
`;
export const GetAQuoteSectionWrapper = styled.div`
  background-color: transparent;
  background-image: linear-gradient(180deg, #fff 0%, #f2f5ff 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -130px;
  margin-bottom: 0;
`;
export const GetAQuoteFormBox = styled.div<{
  py?: string;
  px?: string;
}>`
  padding: ${(props) => (props.py ? props.py : "90px")}
    ${(props) => (props.px ? props.px : "60px")};
  @media (max-width: 600px) {
    padding: ${(props) => (props.py ? props.py : "60px")}
      ${(props) => (props.px ? props.px : "20px")};
  }
`;
export const GetAQuoteImg = styled.div`
  background-image: url("./images/home/home-contact.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`;

export const TextField = styled.input.attrs((props) => ({
  type: props.type || "text",
}))<{
  bradius?: string;
  bgcolor?: string;
}>`
  padding: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f5fb;
  border-radius: ${(props) => (props.bradius ? props.bradius : "0px")};
  color: #454545;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : null)};
  width: 100%;
  max-width: 100%;
  font-size: 18px !important;
  letter-spacing: 1px;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #a6a6a6 !important;
    letter-spacing: 1px;
  }
`;
export const Select = styled.select<{
  bradius?: string;
  bgcolor?: string;
}>`
  padding: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f5fb;
  border-radius: ${(props) => (props.bradius ? props.bradius : "0px")};
  color: #454545;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : null)};
  width: 100%;
  max-width: 100%;
  font-size: 18px !important;
  letter-spacing: 1px;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #a6a6a6 !important;
    letter-spacing: 1px;
  }
`;
export const TextArea = styled.textarea<{
  bradius?: string;
  bgcolor?: string;
}>`
  padding: 16px;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f5fb;
  border-radius: ${(props) => (props.bradius ? props.bradius : "0px")};
  color: #454545;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : null)};
  width: 100%;
  max-width: 100%;
  font-size: 18px !important;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #a6a6a6 !important;
    letter-spacing: 1px;
  }
`;
export const TechnologiesWrapper = styled.div`
  background-color: #0c54b8;
  background-image: url("./images/company/technologies/tech-bg.webp");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 90px 0 100px;
`;
export const TechImgBox = styled.div`
  margin-bottom: 70px;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const TechImg = styled.img`
  width: auto;
  max-height: 62px;
  max-width: 100%;
  @media (max-width: 991px) {
  }
  &.postgresql-logo {
    width: 230px;
  }
`;
export const CareerPageWrapper = styled.div`
  background-color: #f5f6f9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 70px 0;
`;
export const CareerContactUsWrapper = styled.div`
  background-color: transparent;
  background-image: linear-gradient(270deg, #4e95ed 0%, #03228f 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 90px 60px;
  margin-top: 50px;
  z-index: 2;
  border-radius: 5px;
  @media (max-width: 768px) {
    padding: 60px 20px !important;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    padding: 60px 30px !important;
  }
`;
export const WorkProcessWrapper = styled.div`
  background-color: #03228f;
  background-image: url(./images/company/ourProcess/work-process-bg.webp);
  background-position: center center;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0;
`;
export const WorkProcessImg = styled.img`
  border-radius: 80px;
  box-shadow: 5px 12px 30px 5px
    rgba(255, 254.44565217391306, 254.44565217391306, 0.5);
`;
export const ContactInfoWrapper = styled.div`
  background-color: transparent;
  background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 0 0 8px;
  padding: 58px 30px 40px 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactInfoIconBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 25px !important;
`;
export const ContactInfoIcon = styled.div`
  margin-right: 23px !important;
  background-color: transparent;
  background-image: linear-gradient(275deg, #fff 0%, #fcfcff 100%);
  min-width: 48px;
  height: 48px;
  line-height: 45px !important;
  text-align: left;
  border-color: rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  text-align: center;
`;

interface SubmitButtonProps {
  fontSize?: string;
  fontWeight?: string;
  iscaptilize?: boolean;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  font-size: ${(props) => props.fontSize || "15px"};
  text-transform: ${(props) =>
    props.iscaptilize ? "capitalize" : "uppercase"};
  padding: 14px 40px;
  background-color: transparent;
  background-image: linear-gradient(250deg, #4e95ed 19%, #03228f 100%);
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  font-weight: 500;
  line-height: 26px;
  z-index: 1;
  border-width: 0px !important;

  &:hover {
    background-color: transparent;
    background-image: linear-gradient(180deg, #03228f 19%, #4e95ed 100%);
  }
`;

export const CyberIconBox = styled.div`
  border-radius: 10px;
  background-color: transparent;
  background-image: linear-gradient(90deg, #1672de 0%, #071f6b 58%);
  padding: 70px 20px;
  height: 100%;
  & > * {
    color: #fff !important;
  }
`;
