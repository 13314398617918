import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { PortfolioBoxCard } from "../../../components/portfolioBoxCard";
import { MobileAppsList } from "../../../DataJson";

interface IProps {}

export const CardMainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row className="pt-4">
        {MobileAppsList.map((item, index) => {
          return (
            <>
              <Col
                lg={6}
                sm="12"
                className="mb-4"
                key={`mobileAppsList-${index + 1}`}
              >
                <PortfolioBoxCard
                  image={item.image}
                  title={item.title}
                  imgheight="550px"
                  imgWidth="350px"
                  titlealign="center"
                  seeappbtn="See Full App"
                  islightbox={true}
                  linkUrl={item.linkUrl}
                />
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};
