import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Box } from "../../../components/styledComponents";
import { PortfolioBoxCard } from "../../../components/portfolioBoxCard";
import { WebDesignsList } from "../../../DataJson";

interface IProps {}

export const CardMainSection: FC<IProps> = ({}) => {
  return (
    <>
      <Row className="pt-4 mb-5">
        {WebDesignsList.map((item, index) => {
          return (
            <>
              <Col
                lg={4}
                sm="12"
                className="mb-4"
                key={`webdesignsList-${index + 1}`}
              >
                <PortfolioBoxCard
                  image={item.image}
                  title={item.title}
                  titlealign="center"
                  fullImage={item.fullImage}
                />
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};
