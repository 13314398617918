import React, { FC, useState } from "react";
import {
  BodyText,
  Box,
  ButtonGradient,
  DisplayCenter,
} from "./styledComponents";
import {
  PortfolioBox,
  PortfolioImg,
  PortfolioTitle,
  PortfolioSubTitle,
} from "./styledComponents2";
import { Link, useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";

interface IProps {
  image: string;
  imgheight?: string;
  imgWidth?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  linkText?: string;
  linkUrl?: string | null;
  titlealign?: string;
  seeappbtn?: string;
  islightbox?: boolean;
  styleClass?: string;
  fullImage?: string;
}

export const PortfolioBoxCard: FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  const [toggler, setToggler] = useState<boolean>(false);
  return (
    <Box className="h-100">
      <PortfolioBox className="h-100">
        <DisplayCenter>
          {props.islightbox ? (
            <>
              <img
                src={props.image}
                alt="pseb-certificate"
                onClick={() => setToggler(!toggler)}
                className="cursor-pointer"
              />
              <FsLightbox toggler={toggler} sources={[`${props.image}`]} />
            </>
          ) : (
            // <PortfolioImg src={props.image} alt={props.title} imgWidth={props.imgWidth} imgheight={props.imgheight ? props.imgheight : ""} className={`${props.styleClass ? props.styleClass : ''}`} />
            <>
              {/* <img src={props.image} alt="pseb-certificate" onClick={() => setToggler(!toggler)} /> */}
              <PortfolioImg
                onClick={() => setToggler(!toggler)}
                src={props.image}
                alt={props.title}
                imgWidth={props.imgWidth}
                imgheight={props.imgheight ? props.imgheight : ""}
                className={`${
                  props.styleClass
                    ? props.styleClass + " cursor-pointer"
                    : "cursor-pointer"
                }`}
              />
              <FsLightbox
                toggler={toggler}
                sources={[`${props.fullImage ? props.fullImage : props.image}`]}
              />
            </>
          )}
        </DisplayCenter>
        {props.title ? (
          <PortfolioTitle mt="12px" textalign={props.titlealign}>
            {props.title}
          </PortfolioTitle>
        ) : null}
        {props.subTitle ? (
          <PortfolioSubTitle>{props.subTitle}</PortfolioSubTitle>
        ) : null}
        {props.description ? (
          <BodyText textColor="#000" fontSize="18px" fontWeight="500" mb="5px">
            {props.description}
          </BodyText>
        ) : null}
        {props.linkUrl ? (
          <Link
            className="text-decoration-none"
            to={props.linkUrl ? props.linkUrl : "javascript:;"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BodyText textColor="#E47226" fontSize="18px" fontWeight="500">
              {!!props.linkUrl ? props.linkText : ""}
            </BodyText>
          </Link>
        ) : null}

        {props.seeappbtn && (
          <DisplayCenter mt="10px">
            <ButtonGradient
              onClick={() =>
                navigate(props.linkUrl ? props.linkUrl : "javascript;")
              }
            >
              {props.seeappbtn}
            </ButtonGradient>
          </DisplayCenter>
        )}
      </PortfolioBox>
    </Box>
  );
};
